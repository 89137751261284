/* Base Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure the app fills the viewport */
  background-color: #333; /* Dark background */
  color: #f4f7f6; /* Light text color for better visibility */
  padding: 20px; /* Add padding to ensure content is not cut off */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.App-header {
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin-top: 20px; /* Add top margin to ensure header is not cut off */
}

/* Typography */
.App-header h1, .App-header p {
  color: #f4f7f6; /* Light color for text on dark background */
}

.App-header h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.App-header p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Card Styles */
.card {
  background: white; /* Light background for card */
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  padding: 30px;
  width: auto;
  margin: 20px 0;
  color: #333; /* Dark text color for readability */
}

.card .card-title {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 15px;
  color: #333; /* Dark color for text on light background */
}

/* Button Styles */
.btn {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Icon Button Styles */
.btn-icon {
  background-color: #6c757d;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
}

.btn-icon i {
  margin-right: 8px;
}

.btn-icon:hover {
  background-color: #5a6268;
}

/* Theme Switcher Styles */
.theme-switcher {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calculator {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.alert-success p {
  color: #333; /* Dark color for text */
}
